import { IonAvatar, IonCol, IonIcon, IonRow, IonSpinner } from "@ionic/react";
import { fetchAndSetWorkerFriendRequests } from "@src/app/store/workerFriends/actions";
import { FriendLinkType, WorkerFriendRequestStatus } from "@src/app/store/workerFriends/model";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { getFSSProfilePicDistributionURL } from "@src/utils/fss.utils";
import { people, personAddSharp } from "ionicons/icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { sendWorkerFriendRequestByUserId } from "./api";
import { ColleagueWorkDetails } from "./colleagueWorkDetails";
import { FriendLinkAlert } from "./friendLinkAlert";
import { ColleagueInfoProps } from "./interface";

export const ColleagueInfo: FC<ColleagueInfoProps> = ({ colleagueInfo, context }) => {
  const {
    qualification,
    colleagueName,
    colleagueUserId,
    shiftName,
    shiftStart,
    facilityName,
    hasProfilePic,
    isFriend,
    isFriendRequestSent,
  } = colleagueInfo || {};

  const dispatch = useDispatch();

  const [distributionUrl, setDistributionUrl] = useState("");
  const [openAddFriendModal, setOpenAddFriendModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const fetchDistributionUrl = useCallback(async () => {
    try {
      const distributionUrl = await getFSSProfilePicDistributionURL(colleagueUserId);
      setDistributionUrl(distributionUrl);
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while fetching profile picture for colleague info list. ${JSON.stringify(
          error?.stack || error
        )}`,
      });
    }
  }, [colleagueUserId]);

  useEffect(() => {
    if (!hasProfilePic) {
      return;
    }
    fetchDistributionUrl();
  }, [colleagueUserId, fetchDistributionUrl, hasProfilePic]);

  const handleAddFriendButtonClick = () => {
    setOpenAddFriendModal(true);
  };

  const onClickSendFriendRequest = async () => {
    setOpenAddFriendModal(false);
    try {
      setLoading(true);
      await sendWorkerFriendRequestByUserId(colleagueUserId);
      fetchAndSetWorkerFriendRequests(dispatch, WorkerFriendRequestStatus.SENT);
      showSuccessToast("Friend request sent.");
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while sending friend request ${JSON.stringify(error?.stack || error)}`,
      });
      showErrorToast("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  return (
    <IonRow data-testid="colleague-info-row" className="colleague-info-row">
      <IonCol className="colleague-info-avatar" size="auto">
        <IonAvatar
          className={`profile-pic-avatar ${distributionUrl?.length && "profile-pic-avatar-border"}`}
        >
          {isFriend && (
            <div className="colleagueFriendIconContainer" data-testid="colleague-friend-avatar">
              <IonIcon icon={people} color="light" className="friendIcon" />
            </div>
          )}
          {distributionUrl?.length ? (
            <img data-testid="colleague-image" src={distributionUrl} alt="profile" />
          ) : (
            <p data-testid="colleague-initial-avatar">{colleagueName?.charAt(0)?.toUpperCase()}</p>
          )}
        </IonAvatar>
      </IonCol>
      <IonCol className="colleague-info-details">
        <div className="colleague-details">
          <p>{`${colleagueName}, ${qualification}`}</p>
          <ColleagueWorkDetails
            isFriend={isFriend}
            isFriendRequestSent={isFriendRequestSent}
            shiftStart={shiftStart}
            shiftName={shiftName}
            facilityName={facilityName}
          />
        </div>
        {!isFriend &&
          isFriendRequestSent === false &&
          (loading ? (
            <IonSpinner color="primary" />
          ) : (
            <IonIcon
              className="add-friend-icon"
              data-testid="add-friend-icon"
              icon={personAddSharp}
              color="primary"
              onClick={handleAddFriendButtonClick}
              size="large"
            />
          ))}
      </IonCol>
      <FriendLinkAlert
        message={
          "Add {{name}} {{qualification}} as a friend so they know the shifts you are booking."
        }
        header={"Add Friend"}
        isOpen={openAddFriendModal}
        type={FriendLinkType.NEW_FRIEND}
        hasProfilePic={hasProfilePic}
        workerId={colleagueUserId}
        name={colleagueName}
        qualification={qualification}
        imageUrl={distributionUrl}
        handleOnContinueClick={onClickSendFriendRequest}
        closeModal={() => setOpenAddFriendModal(false)}
        context={context}
      />
    </IonRow>
  );
};
