import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { FC } from "react";

import { ColleaguesSummaryProps, IShiftSlot } from "./interface";

const getShiftsStatsText = (shiftSlotStats: IShiftSlot["stats"], assigned: boolean): string => {
  const shiftsStats = Object.keys(shiftSlotStats).map((key) => {
    return { ...shiftSlotStats[key], qualification: key };
  });
  const requiredShifts = shiftsStats.filter((shiftsStat) =>
    assigned ? shiftsStat.assignedShifts > 0 : shiftsStat.assignedShifts < shiftsStat.totalShifts
  );
  requiredShifts.sort((q1Shifts, q2Shifts) => {
    if (assigned) {
      if (q1Shifts.assignedShifts > q2Shifts.assignedShifts) {
        return 1;
      } else if (q1Shifts.assignedShifts < q2Shifts.assignedShifts) {
        return -1;
      } else {
        return 0;
      }
    } else {
      const unassignedQ1Shifts = q1Shifts.totalShifts - q1Shifts.assignedShifts;
      const unassignedQ2Shifts = q2Shifts.totalShifts - q2Shifts.assignedShifts;
      if (unassignedQ1Shifts > unassignedQ2Shifts) {
        return -1;
      } else if (unassignedQ1Shifts < unassignedQ2Shifts) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  let textToShow = "";

  requiredShifts.forEach((qualificationShifts, index) => {
    const { qualification, totalShifts, assignedShifts } = qualificationShifts;

    const count = assigned ? assignedShifts : totalShifts - assignedShifts;
    const qualificationText = count > 1 ? `${qualification}s` : `${qualification}`;
    const countText = count > 2 ? "2+" : count;

    if (index === 0) {
      textToShow += `${countText} ${qualificationText}`;
    } else if (index === requiredShifts.length - 1) {
      textToShow += ` and ${countText} ${qualificationText}`;
    } else {
      textToShow += `, ${countText} ${qualificationText}`;
    }
  });
  return textToShow;
};

export const ColleaguesSummary: FC<ColleaguesSummaryProps> = ({
  colleagueList,
  shiftSlotStats,
  context,
  shiftQualification,
}) => {
  const isShowRemainingShiftsInTheSlotEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_REMAINING_SHIFT_DATA_IN_A_SLOT,
    { defaultValue: false }
  );

  let titleText = "";
  let subTitleText = "";
  const shiftSlotStatsWithoutCurrentUser = cloneDeep(shiftSlotStats);

  if (
    context === "my-shifts" &&
    shiftQualification &&
    shiftSlotStatsWithoutCurrentUser[shiftQualification]?.assignedShifts
  ) {
    shiftSlotStatsWithoutCurrentUser[shiftQualification].assignedShifts -= 1;
    shiftSlotStatsWithoutCurrentUser[shiftQualification].totalShifts -= 1;
  }

  if (colleagueList.length === 0) {
    if (!isShowRemainingShiftsInTheSlotEnabled || context === "my-shifts") {
      return null;
    }
    titleText = `Looking for ${getShiftsStatsText(shiftSlotStatsWithoutCurrentUser, false)}`;
    subTitleText = "Be the first to book this shift";
  } else {
    if (isShowRemainingShiftsInTheSlotEnabled && context !== "my-shifts") {
      subTitleText = `Still Looking for ${getShiftsStatsText(
        shiftSlotStatsWithoutCurrentUser,
        false
      )}`;
    }
    const friendsInColleaguesList = colleagueList.filter(
      (colleague) => colleague.isFriend === true
    );

    if (friendsInColleaguesList.length === 1) {
      titleText = `${friendsInColleaguesList[0].colleagueName} ${
        context === "my-shifts" ? "also" : "already"
      } booked this shift`;
    } else if (friendsInColleaguesList.length > 1) {
      titleText = `${friendsInColleaguesList.length} friends ${
        context === "my-shifts" ? "also" : "already"
      } booked this shift`;
    } else {
      const recentColleaguesInColleaguesList = colleagueList.filter(
        (colleague) => colleague.shiftStart !== undefined
      );
      if (recentColleaguesInColleaguesList.length === 0) {
        let totalNumberOfShiftsBooked = 0;
        Object.values(shiftSlotStatsWithoutCurrentUser).forEach((stat) => {
          totalNumberOfShiftsBooked += stat.assignedShifts;
        });
        const verbToUse = totalNumberOfShiftsBooked > 1 ? "have" : "has";
        const shiftStatsText = getShiftsStatsText(shiftSlotStatsWithoutCurrentUser, true);
        titleText = `${shiftStatsText} ${verbToUse} booked this shift`;
      } else if (recentColleaguesInColleaguesList.length === 1) {
        const [recentColleague] = recentColleaguesInColleaguesList;
        titleText = `You worked with ${recentColleague.colleagueName} ${moment(
          recentColleague.shiftStart
        ).calendar(null, {
          lastDay: "[yesterday]",
          sameDay: "[today]",
          lastWeek: "[last] dddd",
          sameElse: "[on] LL",
        })}`;
      } else {
        titleText = `You worked with ${recentColleaguesInColleaguesList.length} colleagues recently`;
      }
    }
  }

  return (
    <div className="colleague-summary" data-testid="colleague-summary">
      <p id="colleague-summary-title">{titleText}</p>
      {subTitleText && <p className="colleague-summary-subtitle">{subTitleText}</p>}
    </div>
  );
};
