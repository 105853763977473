import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import moment from "moment-timezone";

export const ColleagueWorkDetails = ({
  isFriend,
  isFriendRequestSent,
  shiftStart,
  shiftName,
  facilityName,
}) => {
  if (isFriend) {
    return <p className="colleague-work-details">Your friend</p>;
  }
  if (isFriendRequestSent) {
    return (
      <p className="colleague-work-details">
        <em>Friend request sent</em>
      </p>
    );
  }
  if (shiftStart) {
    return (
      <p className="colleague-work-details">
        {moment(shiftStart).calendar(null, {
          lastDay: "[Yesterday]",
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          lastWeek: "[Last] dddd",
          nextWeek: "dddd",
          sameElse: "[On] LL",
        })}
        , you worked on {`${shiftName === ShiftPeriod.AM ? "an " : "a "} ${shiftName}`} shift at{" "}
        {facilityName} together
      </p>
    );
  }
  return null;
};
