import "./style.scss";
import { ExternalLink } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonBackButton,
  IonButtons,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { useGetShiftColleagues } from "@src/appV2/Shifts/Shift/api/useGetShiftColleagues";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { useLocation } from "react-router-dom";

import { ColleagueInfo } from "./colleagueInfo";
import { ShiftContext, ViewAddFriendModalContext } from "./interface";

/**
 * FIXME
 *
 * Refactor to remove these props and use this component only as a route.
 * Right now, the biggest obstacle to achieve this is "MyShiftDetailsPage"
 * and the complex logic around the usage of this component.
 */
export interface ColleagueListProps {
  shiftId?: string;
  isShiftCompleted?: boolean;
  viewAddFriendModalContext?: ViewAddFriendModalContext;
  context?: ShiftContext;
  onBackClick?: () => void;
}

export function ColleagueList(props: ColleagueListProps) {
  const supportLinks = useCbhFlag(CbhFeatureFlag.SUPPORT_LINKS, {
    defaultValue: {},
  });

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shiftId = searchParams.get("shiftId") ?? props.shiftId ?? undefined;
  const referrer = searchParams.get("referrer") ?? props.context ?? undefined;
  const isShiftCompleted = isDefined(searchParams.get("isShiftCompleted"))
    ? searchParams.get("isShiftCompleted") === "true"
    : props.isShiftCompleted;
  const viewAddFriendModalContext =
    searchParams.get("viewAddFriendModalContext") ??
    props.viewAddFriendModalContext ??
    referrer === "my-shifts"
      ? "COLLEAGUES_LIST_UPCOMING_SHIFT"
      : "COLLEAGUES_LIST_OPEN_SHIFT";

  const { isSuccess: isGetShiftSuccess, data: getShiftData } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId),
  });

  const shift = isGetShiftSuccess ? getShiftData.response : undefined;
  const facilityName = shift?.facility?.name;

  const { data: colleagues } = useGetShiftColleagues({
    shift,
    isShiftCompleted: isShiftCompleted ?? false,
  });

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            {props.onBackClick ? (
              <IconButton
                onClick={props.onBackClick}
                size="large"
                edge="start"
                color="inherit"
                aria-label="Go Back"
                title="Go Back"
                sx={{ marginLeft: 0 }}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IonBackButton
                text=""
                defaultHref={referrer === "my-shifts" ? "/home/myShifts" : "/home/openShifts"}
                mode="ios"
              />
            )}
          </IonButtons>
          <IonTitle class="ion-no-padding ion-text-center">
            {isShiftCompleted ? "Show Colleagues on this Shift" : "Colleagues"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonGrid
        class="ion-text-center"
        style={{
          overflowY: "auto",
        }}
      >
        <IonRow className="colleague-list-row">
          <div>
            {referrer === "my-shifts" ? (
              <p>
                These Clipboard Health colleagues worked with you today. If you want to work
                together in the future, you can add them as a friend.
              </p>
            ) : (
              <>
                <p>Book this shift to work together!</p>
                <p>These colleagues will be working at {facilityName} at the same time.</p>
              </>
            )}

            <ExternalLink
              to={supportLinks?.WORK_WITH_FRIENDS ?? DEFAULT_SUPPORT_LINKS.WORK_WITH_FRIENDS}
            >
              Learn more
            </ExternalLink>
          </div>
        </IonRow>
        {colleagues.map((colleague) => (
          <ColleagueInfo
            colleagueInfo={colleague}
            key={colleague.colleagueUserId}
            // FIXME: This is only used in a log further down the component tree.
            // Investigate a way of removing this weirdly complex prop drilling.
            context={viewAddFriendModalContext}
          />
        ))}
      </IonGrid>
    </IonPage>
  );
}
