import { FC } from "react";

import { ColleagueProfilePicture } from "./colleagueProfilePicture";
import { ColleaguePictureListProps } from "./interface";

export const ColleaguePictureList: FC<ColleaguePictureListProps> = ({ colleagues = [] }) => {
  if (!colleagues?.length) {
    return null;
  }

  return (
    <div className="colleague-profile-picture-list">
      {colleagues.slice(0, 4).map((colleague) => (
        <ColleagueProfilePicture colleague={colleague} key={colleague.colleagueUserId} />
      ))}
    </div>
  );
};
