import "./style.scss";
import { IonAvatar, IonIcon } from "@ionic/react";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { getFSSProfilePicDistributionURL } from "@src/utils/fss.utils";
import { people } from "ionicons/icons";
import { FC, useCallback, useEffect, useState } from "react";

import { ColleagueProfilePictureProps } from "./interface";

export const ColleagueProfilePicture: FC<ColleagueProfilePictureProps> = ({ colleague }) => {
  const { colleagueUserId, colleagueName, hasProfilePic, isFriend } = colleague || {};
  const [distributionUrl, setDistributionUrl] = useState("");

  const fetchDistributionUrl = useCallback(async () => {
    try {
      const distributionUrl = await getFSSProfilePicDistributionURL(colleagueUserId);
      setDistributionUrl(distributionUrl);
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while fetching profile picture for colleague. ${JSON.stringify(
          error?.stack || error
        )}`,
      });
    }
  }, [colleagueUserId]);

  useEffect(() => {
    if (!hasProfilePic) {
      return;
    }
    fetchDistributionUrl();
  }, [colleagueUserId, fetchDistributionUrl, hasProfilePic]);

  return (
    <IonAvatar
      className={`colleague-profile-pic-avatar ${
        distributionUrl?.length && "colleague-profile-pic-avatar-border"
      }`}
      data-testid="colleague-profile-pic-avatar"
    >
      {isFriend && (
        <div className="friendIconContainer" data-testid="colleague-friend-avatar">
          <IonIcon icon={people} color="light" className="friendIcon" />
        </div>
      )}
      {distributionUrl?.length ? (
        <img data-testid="colleague-image" src={distributionUrl} alt="profile" />
      ) : (
        <p data-testid="colleague-initial-avatar" className="colleague-initial-avatar">
          {colleagueName?.charAt(0)?.toUpperCase()}
        </p>
      )}
    </IonAvatar>
  );
};
