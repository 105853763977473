import {
  IWorkerFriend,
  IWorkerFriendRequest,
  WorkerFriendRequestAction,
  WorkerFriendRequestStatus,
} from "@src/app/store/workerFriends/model";
import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import request from "superagent";

import { IFriendLinkAlertProps, IShiftSlot } from "./interface";

const baseUrl = environmentConfig.REACT_APP_BASE_API_URL;

export const fetchShiftSlots = async (shiftSlotRanges): Promise<IShiftSlot[]> => {
  return await request
    .post(`${baseUrl}/shifts/slots/stats`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .send(shiftSlotRanges)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const fetchWorkerFriends = async (): Promise<IWorkerFriend[]> => {
  return await request
    .get(`${baseUrl}/worker/friends/list`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const removeWorkerFriend = async (friendId: string): Promise<boolean> => {
  return await request
    .put(`${baseUrl}/worker/friends/remove?friendId=${friendId}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const fetchWorkerFriendRequests = async (
  status: WorkerFriendRequestStatus
): Promise<IWorkerFriendRequest[]> => {
  return await request
    .get(`${baseUrl}/worker/friends/requests/list?type=${status}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const updateWorkerFriendRequest = async (
  friendRequestId: string,
  action: WorkerFriendRequestAction
): Promise<{ newWorkerFriend?: IWorkerFriend; success: boolean }> => {
  return await request
    .put(
      `${baseUrl}/worker/friends/requests/action?type=${action}&friendRequestId=${friendRequestId}`
    )
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const sendWorkerFriendRequest = async (
  bookWithMeId: string
): Promise<{ newWorkerFriend?: IWorkerFriend; success: boolean }> => {
  return await request
    .put(`${baseUrl}/worker/friends/requests/send?bookWithMeId=${bookWithMeId}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const sendWorkerFriendRequestByUserId = async (
  userId: string
): Promise<{ newWorkerFriend?: IWorkerFriend; success: boolean }> => {
  return await request
    .put(`${baseUrl}/worker/friends/requests/send?workerId=${userId}`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const fetchWorkerProfileByBookWithMeId = async (
  bookWithMeId: string
): Promise<IFriendLinkAlertProps> => {
  return await request
    .get(`${baseUrl}/worker/friends/requests/details?bookWithMeId=${bookWithMeId}`)
    .retry(1, (err) => {
      if (!err || err?.response?.statusCode === 400) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch((error) => {
      if (error?.response?.statusCode !== 400) {
        deprecatedDoNotUseLogError({
          message: `Error while fetching friend profile by bookWithMeId. ${JSON.stringify(
            error?.stack || error
          )}`,
        });
        return;
      }
      const { type, message, header, hasProfilePic, workerId, name, qualification } =
        error?.response?.body || {};
      return {
        type,
        message,
        header,
        hasProfilePic,
        workerId,
        name,
        qualification,
      };
    });
};
